import React from "react";
import Snackbar from "@mui/joy/Snackbar";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function SnackbarInfo(props: {
  info: string;
  color: "success" | "warning" | "primary" | "neutral" | "danger" | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div>
      <Snackbar
        autoHideDuration={4000}
        open={props.open}
        variant="outlined"
        color={props.color}
        startDecorator={props.color === "success" ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
        onClose={(_event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          props.setOpen(false);
        }}
      >
        {props.info}
      </Snackbar>
    </div>
  );
}
