import { Box, Button, Typography } from "@mui/joy";
import Alert from "@mui/joy/Alert";
import Warning from "@mui/icons-material/Warning";

export default function LoginError(props: { loginError: boolean }) {
  if (props.loginError) {
    return (
      <Alert
        variant="soft"
        color="danger"
        invertedColors
        startDecorator={<Warning />}
        sx={{ alignItems: "flex-start", gap: "1rem" }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography level="title-md">
            Napačno uporabniško ime ali geslo!
          </Typography>
          <Typography level="body-sm">
            Prosim preverite pravilnost vnešenih podatkov.
          </Typography>
          {/* <Box
            sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 1 }}
          >
            <Button variant="outlined" size="sm">
              Open network settings
            </Button>
            <Button variant="solid" size="sm">
              Try again
            </Button>
          </Box> */}
        </Box>
      </Alert>
    );
  } else {
    return <></>;
  }
}
