import React from "react";
import "../App.css";
import Button from "@mui/joy/Button";
import {
  ButtonGroup,
  DialogTitle,
  Modal,
  ModalDialog,
} from "@mui/joy";
import axios from "axios";

import ValidationSnackbar from "./ValidationSnackbar";

type Content = {
  id: number;
  name_id: string;
  text_content: string;
};

export default function RemoveModal(props: {
  openRemove: boolean;
  setOpenRemove: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  submitted: boolean;
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowInfo: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let openRemove = props.openRemove;
  let setOpenRemove = props.setOpenRemove;
  let name = props.name;

  const [loading, setLoading] = React.useState(false);

  // validationsnackbar states
  const [showInfo, setShowInfo] = React.useState(false);
  const [info, setInfo] = React.useState("Sporočilo o uspešnosti");
  const [infoColor, setInfoColor] = React.useState<
    "success" | "warning" | "primary" | "neutral" | "danger" | undefined
  >("success");

  async function removeContent() {
    setLoading(true);
    await axios
      .get("https://hudnik-db.shuttleapp.rs/content/" + name + "/remove")
      .then(function (response) {
        console.log(response);
        props.setSubmitted(!props.submitted);
        props.setName("");

        // Set all the info snackbar states
        setShowInfo(true);
        setInfo("Podatek uspešno izbrisan!");
        setInfoColor("success");
      })
      .catch(function (error) {
        console.log(error);
        // Set all the info snackbar states
        setShowInfo(true);
        setInfo(error.response.data.message);
        setInfoColor("danger");
      });
    setShowInfo(true);
    setLoading(false);
  }

  return (
    <>
      <Modal open={openRemove} onClose={() => setOpenRemove(false)}>
        <ModalDialog size="lg" layout="center">
          <DialogTitle>Želiš izbrisati podatek "{name}"?</DialogTitle>
          <ButtonGroup spacing="5rem">
            <Button
              color="neutral"
              onClick={() => {
                setOpenRemove(false);
              }}
            >
              Prekliči
            </Button>
            <Button
              color="danger"
              loading={loading}
              onClick={() => {
                setOpenRemove(false);
                removeContent();
              }}
            >
              Izbriši
            </Button>
          </ButtonGroup>
          {/* <Stack spacing={2}>
                    <form
                        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            // if (event.currentTarget.elements.name != "") {
                            setOpenRemove(false);
                            createContent(new_name, new_content);
                            // }
                            console.log("Success for " + new_name + " " + new_content)
                        }}
                    >
                        <FormControl>
                            <FormLabel>Podatek</FormLabel>
                            <Input type="name" name="name" onChange={e => set_new_name(e.currentTarget.value)} autoFocus required />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Vsebina</FormLabel>
                            <Textarea
                                size="sm"
                                minRows={10}
                                sx={{ mt: 4 }}
                                onChange={e => set_new_content(e.currentTarget.value)}
                                required
                            />
                        </FormControl>
                        <Button type="submit">Submit</Button>
                    </form>

                </Stack> */}
        </ModalDialog>
      </Modal>
      <ValidationSnackbar
        info={info}
        open={showInfo}
        setOpen={setShowInfo}
        color={infoColor}
      />
    </>
  );
}
