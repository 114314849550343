import React from "react";
import "../App.css";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import {
  DialogTitle,
  Modal,
  ModalDialog,
} from "@mui/joy";
import axios from "axios";
import Add from "@mui/icons-material/Add";
import ValidationSnackbar from "./ValidationSnackbar";

export default function AddNewModal(props: {
  open: boolean;
  websites: string[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setWebsite: React.Dispatch<React.SetStateAction<string>>;
  submitted: boolean;
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let open = props.open;
  let websites = props.websites;
  let setOpen = props.setOpen;

  const [new_name, set_new_name] = React.useState<string>("");
  const [choose_website, set_choose_website] = React.useState<string>("");
  const [new_content, set_new_content] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  // validation snackbar states
  const [addInfo, setAddInfo] = React.useState<string>("");
  const [addSuccess, setAddSuccess] = React.useState<"success" | "warning" | "primary" | "neutral" | "danger" | undefined>(undefined);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  async function createContent(
    new_name: string,
    new_content: string,
    website: string,
  ) {
    await axios
      .post("https://hudnik-db.shuttleapp.rs/content", {
        name_id: new_name,
        text_content: new_content,
        website: website,
      })
      .then(function (response) {
        // console.log(response);
        props.setSubmitted(!props.submitted);
        props.setName(new_name);
        props.setWebsite(website);
        console.log(response);
        setAddInfo("Podatek \'" + new_name + "\' uspešno dodan!");
        setAddSuccess("success");

      })
      .catch(function (error) {
        console.log(error);
        setAddInfo("Napaka: " + error.code + ": " + error.message);
        setAddSuccess("danger");
      });
  }

  async function verifyContent() {
    setLoading(true);
    await createContent(
      new_name.replace(/\s/g, ""),
      new_content,
      choose_website,
    );
    setLoading(false);
    setOpenSnackbar(true);
    setOpen(false);
  }

  return (
    <>
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog size="lg" layout="center">
        <DialogTitle>Dodaj podatek</DialogTitle>
        <Stack spacing={1}>
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              verifyContent();
              console.log("Success for " + new_name + " " + new_content);
            }}
          >
            <FormControl>
              <FormLabel>Podatek</FormLabel>
              <Input
                type="name"
                name="name"
                onChange={(e) => set_new_name(e.currentTarget.value)}
                autoFocus
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Spletna stran</FormLabel>
              <Select
                onChange={(e, selectedWebsite) => {
                  if (selectedWebsite != null) {
                    set_choose_website(selectedWebsite as string);
                  }
                  if (selectedWebsite === "") {
                    set_choose_website("");
                  }
                }}
              >
                {websites.map((ws) => {
                  return <Option value={ws}>{ws}</Option>;
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Vsebina</FormLabel>
              <Textarea
                size="sm"
                minRows={10}
                maxRows={15}
                onChange={(e) => set_new_content(e.currentTarget.value)}
                required
              />
            </FormControl>
            <Button
              sx={{ mt: 4 }}
              type="submit"
              startDecorator={<Add />}
              loading={loading}
            >
              Dodaj
            </Button>
          </form>
        </Stack>
      </ModalDialog>
      </Modal>
      <ValidationSnackbar
        info={addInfo}
        color={addSuccess}
        open={openSnackbar}
        setOpen={setOpenSnackbar}

      />
      </>
  );
}
