import { Box, Button, Chip, DialogTitle, Divider, Dropdown, FormControl, FormLabel, IconButton, Input, Link, List, ListDivider, ListItem, ListItemContent, Menu, MenuButton, MenuItem, Modal, ModalDialog, Skeleton, Typography } from "@mui/joy";
import axios from "axios";
import { useEffect, useState } from "react";
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { Add, Update } from "@mui/icons-material";

type Concert = {
    id: number;
    title: string;
    concerttime: string;
    venue: string;
    info: string;
    image_url: string;
    ord: number;
}

let emptyConcert: Concert = {
    id: 1,
    title: "title",
    concerttime: "concerttime",
    venue: "venue",
    info: "info",
    image_url: "image_url",
    ord: 0,
}

async function deleteConcert(concert: Concert) {
    await axios.delete(`https://hudnik-db.shuttleapp.rs/concerts/delete/${concert.id}`)
    .then((response) => {
        console.log(response)
    })
    .catch((err) => {
        console.log(err)
    })
}

async function postConcert(concert: Concert, addingNew: boolean = false) {
    let url = `https://hudnik-db.shuttleapp.rs/concerts/update/${concert.id}`;
    if (addingNew) {
      url = `https://hudnik-db.shuttleapp.rs/concerts/`;
    }
    await axios.post(url, {
        id: concert.id,
        title: concert.title,
        concerttime: concert.concerttime,
        venue: concert.venue,
        info: concert.info,
        image_url: concert.image_url,
        ord: concert.ord,
    })
    .then((response) => {
        console.log(response)
    })
    .catch((err) => {
        console.log(err)
    })
}

export default function Concerts(props: {isGuest: boolean}) {

    const [concertsList, setConcertsList] = useState<Concert[]>([emptyConcert]);
    const [loading, setLoading] = useState<boolean>(true);
    const [concertToEdit, setConcertToEdit] = useState<Concert>(emptyConcert);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [newConcert, setNewConcert] = useState<Concert>(emptyConcert);
    const [addNew, setAddNew] = useState<boolean>(false);

    useEffect(() => {
        axios.get('https://hudnik-db.shuttleapp.rs/concerts')
            .then((response) => {
                let concert_iter : Concert[] = [];
                response.data.map((row : Concert) => 
                {
                    concert_iter.push({
                        id: row.id,
                        title: row.title,
                        concerttime: row.concerttime,
                        venue: row.venue,
                        info: row.info,
                        image_url: row.image_url,
                        ord: row.ord,
                    })
                })
                setConcertsList(concert_iter);
                setLoading(false);
            })
            .catch((err) => console.log("When getting the list of all concerts, the following error occurred: " + err))

    }, [openModal])

    return (<>

            <Button
              size="md"
              variant="solid"
              color="primary"
              disabled={props.isGuest}
              startDecorator={<Add />}
              onClick={() => {
                setOpenModal(true)
                setAddNew(true)
                setNewConcert(emptyConcert)
                }}
            >
              Dodaj
            </Button>
        {concertsList.map((concert) =>
        <List
            // key={concert.id}
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItem
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                }}
            >
                <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                    {/* <ListItemDecorator>
                                <Chip
                                    variant="soft"
                                    size="sm"
                                    color={
                                        {
                                            V: 'warning',
                                            N: 'primary',
                                            K: 'success',
                                        }[termin.termin_type] as ColorPaletteProp
                                    }
                                >
                                    {{
                                        V: "Vaja",
                                        N: "Nastop",
                                        K: "Koncert",
                                    }[termin.termin_type]}
                                </Chip>
                            </ListItemDecorator> */}
                    <div>
                        {/* <Typography fontWeight={600} gutterBottom>
                            <Skeleton loading={loading}>
                                <Chip
                                    variant="soft"
                                    size="md"
                                    color={
                                        {
                                            V: 'warning',
                                            N: 'primary',
                                            K: 'success',
                                        }[termin.termin_type] as ColorPaletteProp
                                    }
                                >
                                    {{
                                        V: "Vaja",
                                        N: "Nastop",
                                        K: "Koncert",
                                    }[termin.termin_type]}
                                    Chip
                                </Chip>
                            </Skeleton>
                        </Typography> */}
                        {/* <Typography level="body-md" gutterBottom>
                                    {termin.location}
                                </Typography> */}
                        
                        
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'left',
                                justifyContent: 'left',
                                gap: 0.5,
                                mb: 0.5,
                                mt: 1,
                            }}
                        >

                            <Typography level="body-md"><Skeleton loading={loading} variant="rectangular" width={200} height="1em" sx={{ mb: 1 }}>Title: {concert.title}</Skeleton>
                            <Skeleton loading={loading} variant="rectangular" width={200} height="1em" sx={{ mb: 1 }}> &bull; </Skeleton>
                            <Skeleton loading={loading} variant="rectangular" width={200} height="1em" sx={{ mb: 1 }}>Info: {concert.info}</Skeleton></Typography>
                        </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, mb: 1 }}>
                                <Typography level="body-md">Concert Time: 
                                <Skeleton loading={loading} variant="rectangular" width={200} height="2em" sx={{ mb: 1 }}>
                                    {concert.concerttime}
                                    </Skeleton></Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, mb: 1 }}>
                                <Typography level="body-md">Venue: 
                                <Skeleton loading={loading} variant="rectangular" width={200} height="2em" sx={{ mb: 1 }}><Link href={`https://maps.google.com/?q=${concert.venue.replaceAll(' ', '+')}`} target="_blank" level="body-xs">
                                    {concert.venue}
                                    </Link></Skeleton></Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                <Typography level="body-md">Image URL:
                                <Skeleton loading={loading} variant="rectangular" height="2em" sx={{ mb: 1 }}><Link href={concert.image_url} target="_blank" level="body-xs">
                                    {concert.image_url}
                                    </Link></Skeleton></Typography>
                                    </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, mb: 1 }}>
                                <Typography level="body-md">Order: {concert.ord}</Typography>
                                                                </Box>

                                <RowMenu concert={concert} setConcertToEdit={setConcertToEdit} openModal={setOpenModal} isGuest={props.isGuest} />
                    </div>
                </ListItemContent>
                {/* <Chip
                            variant="soft"
                            size="sm"
                            color={
                                {
                                    V: 'warning',
                                    N: 'primary',
                                    K: 'success',
                                }[termin.termin_type] as ColorPaletteProp
                            }
                        >
                            {{
                                V: "Vaja",
                                N: "Nastop",
                                K: "Koncert",
                            }[termin.termin_type]}
                        </Chip> */}
            </ListItem>
            <ListDivider />
        </List>
        
        
    )}
        <Modal open={openModal} onClose={() => {setOpenModal(false); setAddNew(false)}}>
            <ModalDialog>
                <DialogTitle>
                    Edit concert
                </DialogTitle>
                <FormControl>
                    <FormLabel>Title</FormLabel>
                    <Input
                        defaultValue={concertToEdit.title.toString()}
                        type="name"
                        name="name"
                        onChange={(e) => {
                            let tempConcert: Concert = {
                                id: concertToEdit.id,
                                title: e.currentTarget.value,
                                concerttime: concertToEdit.concerttime,
                                venue: concertToEdit.venue,
                                info: concertToEdit.info,
                                image_url: concertToEdit.image_url,
                                ord: concertToEdit.ord,
                            }
                            setConcertToEdit(tempConcert)
                        }}
                        autoFocus
                        required
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Concert Time</FormLabel>
                    <Input
                        defaultValue={concertToEdit.concerttime.toString()}
                        type="name"
                        name="name"
                        onChange={(e) => {
                            let tempConcert: Concert = {
                                id: concertToEdit.id,
                                title: concertToEdit.title,
                                concerttime: e.currentTarget.value,
                                venue: concertToEdit.venue,
                                info: concertToEdit.info,
                                image_url: concertToEdit.image_url,
                                ord: concertToEdit.ord,
                            }
                            setConcertToEdit(tempConcert)
                        }}
                        autoFocus
                        required
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Venue</FormLabel>
                    <Input
                        defaultValue={concertToEdit.venue.toString()}
                        type="name"
                        name="name"
                        onChange={(e) => {
                            let tempConcert: Concert = {
                                id: concertToEdit.id,
                                title: concertToEdit.title,
                                concerttime: concertToEdit.concerttime,
                                venue: e.currentTarget.value,
                                info: concertToEdit.info,
                                image_url: concertToEdit.image_url,
                                ord: concertToEdit.ord,
                            }
                            setConcertToEdit(tempConcert)
                        }}
                        autoFocus
                        required
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Info</FormLabel>
                    <Input
                        defaultValue={concertToEdit.info.toString()}
                        type="name"
                        name="name"
                        onChange={(e) => {
                            let tempConcert: Concert = {
                                id: concertToEdit.id,
                                title: concertToEdit.title,
                                concerttime: concertToEdit.concerttime,
                                venue: concertToEdit.venue,
                                info: e.currentTarget.value,
                                image_url: concertToEdit.image_url,
                                ord: concertToEdit.ord,
                            }
                            setConcertToEdit(tempConcert)
                        }}
                        autoFocus
                        required
                    />
                </FormControl><FormControl>
                    <FormLabel>Image URL</FormLabel>
                    <Input
                        defaultValue={concertToEdit.image_url.toString()}
                        type="name"
                        name="name"
                        onChange={(e) => {
                            let tempConcert: Concert = {
                                id: concertToEdit.id,
                                title: concertToEdit.title,
                                concerttime: concertToEdit.concerttime,
                                venue: concertToEdit.venue,
                                info: concertToEdit.info,
                                image_url: e.currentTarget.value,
                                ord: concertToEdit.ord,
                            }
                            setConcertToEdit(tempConcert)
                        }}
                        autoFocus
                        required
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Order</FormLabel>
                    <Input
                        defaultValue={concertToEdit.ord}
                        type="number"
                        name="name"
                        onChange={(e) => {
                          let ord = 0
                            try {
                              ord = parseInt(e.currentTarget.value)
                            } 
                            catch (e) {
                              console.log(e)
                            }
                            let tempConcert: Concert = {
                                id: concertToEdit.id,
                                title: concertToEdit.title,
                                concerttime: concertToEdit.concerttime,
                                venue: concertToEdit.venue,
                                info: concertToEdit.info,
                                image_url: concertToEdit.image_url,
                                ord: ord,
                            }
                            setConcertToEdit(tempConcert)
                        }}
                        autoFocus
                        required
                    />
                </FormControl>
                <Button
                    sx={{ mt: 4 }}
                    onClick={async () => {
                        setOpenModal(false)
                        await postConcert(concertToEdit, addNew)
                        setAddNew(false)
                    }
                    }
                    startDecorator={addNew ? <Add /> : <Update />}
                    loading={loading}
                >
                    { addNew ? 'Dodaj' : 'Posodobi'}
                </Button>
            </ModalDialog>
        </Modal>
    </>)
}

function RowMenu(props: { concert: Concert, setConcertToEdit: React.Dispatch<React.SetStateAction<Concert>>, openModal: React.Dispatch<React.SetStateAction<boolean>>, isGuest: boolean }) {
    return (
        <>
            <Dropdown>
                <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}>
                    <MoreHorizRoundedIcon />
                </MenuButton>
                <Menu size="sm" sx={{ minWidth: 140 }}>
                    <MenuItem
                        disabled={props.isGuest}
                        onClick={() => {
                            props.setConcertToEdit(props.concert);
                            props.openModal(true);
                        }}
                    >Edit</MenuItem>
                    <Divider />
                    <MenuItem 
                        color="danger" 
                        disabled={props.isGuest} 
                        onClick={
                            () => deleteConcert(props.concert)
                            }>Delete</MenuItem>
                </Menu>
            </Dropdown>

        </>
    );
}
